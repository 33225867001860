import React from 'react';
import './App.css';
//import BasicTableComponent from './Components/basic.table';
//import PaginationTableComponent from './Components/pagination.table'
import FilterTableComponent from './Components/filter.table'
//import 'bootstrap/dist/css/bootstrap.min.css';/
//import SmartDataTable from 'react-smart-data-table'

//import './custom.scss';
function App() {
  
  return (
    <div className="App">

      {/* <h3>3D<a href>Measureup</a> Metrics Definitions</h3> */}

      {/* <BasicTableComponent /> */}
      {/* <PaginationTableComponent/> */}
      <FilterTableComponent/>
    </div>
  );
}

export default App;


// [
//   {
//       Header: 'ID',
//       accessor: 'ID',
//   },
//   {
//       Header: 'Label',
//       accessor: 'Label',
//   },
//   {
//       Header: 'Type',
//       accessor: 'Type',
//   },{
//       Header: 'Definition',
//       accessor: 'Definition',
//   },{
//       Header: 'L1 Validation',
//       accessor: 'L1 Validation',
//   },{
//       Header: 'Region',
//       accessor: 'Region',
//   },{
//       Header: 'Tape / Contoured',
//       accessor: 'Tape / Contoured',
//   },{
//       Header: 'Aligned to Skeleton',
//       accessor: 'Aligned to Skeleton',
//   },{
//       Header: 'Perpendicular Axis',
//       accessor: 'Perpendicular Axis',
//   },{
//       Header: 'ML Identification',
//       accessor: 'ML Identification',
//   },{
//       Header: 'Posenet ML/3DMU Front ML/3D MU Back ML',
//       accessor: 'Posenet ML/\n3DMU Front ML/\n3D MU Back ML',
//   },{
//       Header: 'ML Ignored?',
//       accessor: 'ML Ignored?',
//   },{
//       Header: 'Geometry Based',
//       accessor: 'Geometry Based',
//   },{
//       Header: 'Geometry Correction',
//       accessor: 'Geometry Correction',
//   },{
//       Header: 'Primary Landmark',
//       accessor: 'Primary Landmark',
//   },{
//       Header: 'Dependent Landmark',
//       accessor: 'Dependent Landmark',
//   },{
//       Header: 'Source of Definition',
//       accessor: 'Source of Definition',
//   },
// ]